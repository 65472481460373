<template>
  <div
    v-if="itemList.length"
    class="faq"
    :class="themeColorClass"
  >
    <div class="faq__header">
      <UiLabel :label="label" />

      <UiHeader
        v-if="header"
        :header="header"
        :subheader="subheader"
        :header-layout="headerLayout"
        :header-position="headerPosition"
        :header-size="headerSize"
        :theme-color="themeColor"
      />
    </div>

    <UiAccordion
      :list="itemList"
      :with-numbers="checkboxNested"
    />
  </div>
</template>

<script setup lang="ts">
import { UiAccordion, UiHeader, UiLabel } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import type { UiCeProductBoxesProps } from '~ui/types'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)
</script>

<style lang="scss">
.faq {
  &__header {
    padding-block: rem(16px);

    & .ui-label {
      border-right: 1px solid var(--color__border-color);
    }

    & > * + * {
      margin-top: rem(40px);
    }
  }

  @include media-query(sm) {
    &__header {
      padding-left: rem(24px);
      border-left: 1px solid var(--color__border-color);

      & .ui-header {
        margin-left: rem(160px);
      }
    }

    & .ui-accordion {
      margin-left: rem(185px);
    }
  }
}
</style>
